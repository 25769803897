/*
  A list of icons the users can select from.
*/

export {
  faInfo,
  faAsterisk,
  faBalanceScale,
  faBatteryThreeQuarters,
  faBolt,
  faBriefcase,
  faBug,
  faBuilding,
  faCalendar,
  faCalendarWeek,
  faCamera,
  faCar,
  faChartArea,
  faCheck,
  faChartBar,
  faChartLine,
  faChartPie,
  faCity,
  faClock,
  faCloudSunRain,
  faDroplet,
  faExclamation,
  faEye,
  faFan,
  faHardHat,
  faFaucet,
  faFilm,
  faFire,
  faFireExtinguisher,
  faFireFlameSimple,
  faFlag,
  faFlask,
  faGlobe,
  faHandHoldingWater,
  faHands,
  faHandsHelping,
  faHandsHolding,
  faHeartbeat,
  faHome,
  faHouseChimney,
  faImage,
  faIndustry,
  faLeaf,
  faLightbulb,
  faMap,
  faMapMarked,
  faMapMarker,
  faMusic,
  faPlug,
  faRestroom,
  faRoad,
  faRotate,
  faShoppingCart,
  faSignal,
  faSitemap,
  faSnowflake,
  faSolarPanel,
  faSpa,
  faTable,
  faTableCellsLarge,
  faTableList,
  faTemperatureHigh,
  faTemperatureHalf,
  faScaleBalanced,
  faLocationPin,
  faUserGroup,
  faHeartPulse,
  faCartShopping,
  faTint,
  faDropletSlash,
  faTintSlash,
  faTruck,
  faHouse,
  faTv,
  faUserFriends,
  faUserGraduate,
  faUsers,
  faVenusMars,
  faVideo,
  faWarehouse,
  faWater,
  faWifi,
  faWrench,
  faTriangleExclamation,
  faThermometerHalf,
  faPercent,
  faTachometer,
  faBurn,
  faPowerOff,
  faHandHoldingDroplet,
  faBuildings,
  faCarBuilding,
  faHouseBuilding,
  faApartment,
  faWindTurbine,
  faBatteryBolt,
  faBatteryEmpty,
  faBatteryExclamation,
  faBatteryFull,
  faBatteryHalf,
  faBatteryLow,
  faBatterySlash,
  faChargingStation,
  faIndustryWindows,
  faMeter,
  faMeterDroplet,
  faMeterFire,
  faPipeValve,
  faUtilityPole,
  faPump,
  faPeopleArrowsLeftRight,
  faPeopleGroup,
  faPersonArrowDownToLine,
  faPersonArrowUpFromLine,
  faPersonBiking,
  faPersonChalkboard,
  faPersonFalling,
  faPollPeople,
  faUserClock,
  faTruckContainer,
  faDolly,
  faTaxiBus,
  faPlane,
  faShip,
  faTransformerBolt,
  faHelmetSafety,
  faEuroSign,
  faEarthEurope,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleChevronUp,
  faCircleChevronDown,
  faFolderTree,
  faSensor,
  faSensorCloud,
  faSensorFire,
  faSensorOn,
  faSensorTriangleExclamation,
  faAirConditioner,
  faTemperatureArrowDown,
  faTemperatureArrowUp,
  faTemperatureEmpty,
  faTemperatureFull,
  faTemperatureList,
  faTemperatureLow,
  faTemperatureSnow,
  faTemperatureSun,
  faTreeCity,
  faTreeDeciduous,
  faHandshakeSimple,
  faHouseCircleCheck,
  faHouseDay,
  faHouseNight,
  faToggleOn,
  faToggleOff,
  faLightbulbOn,
  faGasPump,
  faSeedling,
  faOilWell,
  faFireFlameCurved,
  faCarBolt,
  faTruckBolt,
  faTruckFlatbed,
  faChartUser,
  faChartMixed,
  faTrailer,
  faCars,
  faCarSideBolt,
  faPersonWalkingLuggage,
  faMapLocationDot,
} from '@fortawesome/pro-solid-svg-icons'